import {
  AccountBox,
  AddCircle,
  CancelOutlined,
  CheckBoxOutlined,
  CloseOutlined,
  Edit,
  FileCopy,
  HourglassEmpty,
  NoMeetingRoomOutlined,
  NotInterestedOutlined,
} from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, Paper, Tooltip, useTheme } from '@mui/material';
import { useConfigContext, useTableContext, useTableState } from 'hooks';
import MaterialReactTable from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { paths } from 'routes';
import { ComonEnums } from 'services/comon';
import { MatchingActioner } from 'services/matching';
import { SubjectActioner } from 'services/subject';
import { ThemeSubjectActioner } from 'services/themeSubject';
import { VolunteerActioner } from 'services/volunteer';
import { tokens } from 'themes';

function VolunteerListComponent(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState({ loading: false, docs: [], subjects: [], themeSubjects: [], matchings: [] });
  const history = useHistory();
  const tableRef = useTableContext();
  const tableState = useTableState('volunteer_list');
  const { campaign, site, config } = useConfigContext();
  const interventions = useMemo(() => ComonEnums.getOtherInterventionList(), []);
  useEffect(() => {
    setData(f => ({ ...f, loading: true }));
  }, [config]);

  useEffect(() => {
    if (data.loading) {
      VolunteerActioner.list(campaign, site).then(docs => {
        SubjectActioner.list().then(subjects => {
          ThemeSubjectActioner.list().then(themeSubjects => {
            MatchingActioner.list(campaign, site).then(matchings => {
              setData({ loading: false, docs, subjects, themeSubjects, matchings });
            });
          });
        });
      });
    }
    return () => {};
  }, [data.loading, campaign, site]);

  const {
    messages: {
      scenes: {
        volunteer: { list: intl },
      },
    },
  } = useIntl();
  const columnTitles = intl.columnTitles;

  const columns = useMemo(
    () => [
      {
        header: 'Statut',
        accessorKey: 'draft',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const external = cell.row.original.external;
          const stopped = cell.row.original.stopped;
          if (stopped) {
            return <CancelOutlined sx={{ color: colors.redAccent[700] }} />;
          }
          if (external) {
            return <NoMeetingRoomOutlined sx={{ color: colors.redAccent[700] }} />;
          }
          if (value) {
            return <HourglassEmpty sx={{ color: colors.redAccent[500] }} />;
          }
          return <CheckBoxOutlined sx={{ color: colors.greenAccent[400] }} />;
        },
        enableColumnFilter: false,
      },
      {
        header: 'Matching',
        id: 'matching',
        accessorFn: row => {
          const matching = data.matchings.find(m => m.volunteers?.includes(row._id));
          const status = matching?.status;
          if (!status) {
            return (
              <Tooltip title="Pas de matching" arrow>
                <NotInterestedOutlined sx={{ color: colors.redAccent[700] }} />
              </Tooltip>
            );
          }
          if (status === 'CLOSED') {
            return (
              <Tooltip title="Matching fermé" arrow>
                <CloseOutlined sx={{ color: colors.redAccent[700] }} />
              </Tooltip>
            );
          }
          if (['DRAFT', 'VALIDED'].includes(status)) {
            return (
              <Tooltip title="Matching en cours de validation" arrow>
                <HourglassEmpty sx={{ color: colors.grey[500] }} />
              </Tooltip>
            );
          }
          return (
            <Tooltip title="Matching validé" arrow>
              {<CheckBoxOutlined sx={{ color: colors.greenAccent[400] }} />}
            </Tooltip>
          );
        },
        filterVariant: 'select',
        filterSelectOptions: [
          'Pas de matching',
          'Matching en cours de validation',
          'Matching validé',
          'Matching fermé',
        ],
        filterFn: 'customMatchingFilter',
      },
      {
        header: columnTitles.other_intervention,
        accessorKey: 'other_intervention',
        Cell: ({ cell }) => (
          <div>
            {cell.getValue() !== undefined && Array.isArray(cell.getValue())
              ? cell.getValue().join(' - ')
              : cell.getValue()}
          </div>
        ),
        filterVariant: 'select',
        filterSelectOptions: interventions,
      },
      {
        header: columnTitles.mobile,
        accessorKey: 'general_information.mobile',
        enableColumnFilter: false,
      },
      {
        header: columnTitles.last_name,
        accessorKey: 'general_information.last_name',
        enableColumnFilter: false,
      },
      {
        header: columnTitles.first_name,
        accessorKey: 'general_information.first_name',
        enableColumnFilter: false,
      },
      {
        header: columnTitles.email,
        accessorKey: 'general_information',
        Cell: ({ cell }) => cell.getValue()?.email?.email ?? '',
        enableColumnFilter: false,
      },
      {
        header: columnTitles.nbInterviews,
        accessorKey: 'nb_interviews',
        enableColumnFilter: false,
      },
      {
        header: 'Matières Proposées',
        id: 'proposed_subject',
        accessorFn: row => {
          const value = row.proposed_subject;
          if (!value) {
            return '';
          }
          const themes = value.map((subject, index) => {
            const subjectName = data.subjects.find(s => s._id === subject);
            const themeSubject = data.themeSubjects.find(s => s._id === subjectName?.name) ?? { name: 'Inconnu' };
            return themeSubject.name;
          });
          // Remove duplicates
          const uniqueThemes = [...new Set(themes)];
          return uniqueThemes.map((theme, index) => <div key={index}>{theme}</div>);
        },
        filterVariant: 'select',
        filterSelectOptions: data.themeSubjects.map(({ name }) => name),
        filterFn: 'customThemeSubjectFilter',
      },
      {
        header: 'BI',
        accessorKey: 'has_bi',
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!value) {
            return <HourglassEmpty sx={{ color: colors.redAccent[500] }} />;
          }
          return <CheckBoxOutlined sx={{ color: colors.greenAccent[400] }} />;
        },
        enableColumnFilter: false,
        size: 100,
      },
      {
        header: 'B3',
        accessorKey: 'family_ressources.b3',
        enableColumnFilter: false,
        size: 100,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          if (!value) {
            return <HourglassEmpty sx={{ color: colors.grey[400] }} />;
          }
          return <CheckBoxOutlined sx={{ color: colors.greenAccent[400] }} />;
        },
      },
    ],
    [
      columnTitles.other_intervention,
      columnTitles.mobile,
      columnTitles.last_name,
      columnTitles.first_name,
      columnTitles.email,
      columnTitles.nbInterviews,
      interventions,
      data.themeSubjects,
      data.matchings,
      data.subjects,
      colors.greenAccent,
      colors.redAccent,
      colors.grey,
    ],
  );

  if (data.loading) {
    return (
      <Paper className="padding-small">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className="height-circular"
        >
          <CircularProgress color="primary" />
        </Grid>
      </Paper>
    );
  }

  return (
    <MaterialReactTable
      columns={columns}
      data={data.docs}
      enableDensityToggle={false}
      muiTablePaginationProps={{
        rowsPerPageOptions: [10, 25, 50, 100, 150, { label: 'All', value: data?.docs.length ?? 0 }],
      }}
      positionActionsColumn="last"
      positionGlobalFilter="left" //show the global filter on the left side of the top toolbar
      initialState={{
        showGlobalFilter: true, //show the global filter by default
        density: 'compact',
        // showColumnFilters: true,
        pagination: {
          pageSize: 25,
        },
      }}
      muiTableBodyCellProps={{
        //simple styling with the `sx` prop, works just like a style prop in this example
        sx: {
          fontWeight: 'normal',
          fontSize: '110%',
        },
      }}
      filterFns={{
        customThemeSubjectFilter: (row, id, filterValue) => {
          const themeSubject = data.themeSubjects.find(s => s.name === filterValue);
          const subjects = data.subjects.filter(s => s.name === themeSubject?._id);
          return row.original[id]?.some(subject => subjects.some(s => s._id === subject)) ?? false;
        },
        customMatchingFilter: (row, id, filterValue) => {
          const matching = data.matchings.find(m => m.volunteers?.includes(row.original._id));
          const status = matching?.status;
          if (filterValue === 'Pas de matching') {
            return !status;
          }
          if (filterValue === 'Matching en cours de validation') {
            return ['DRAFT', 'VALIDED'].includes(status);
          }
          if (filterValue === 'Matching validé') {
            return ['POSITIONED', 'LOCK'].includes(status);
          }
          if (filterValue === 'Matching fermé') {
            return status === 'CLOSED';
          }
          return false;
        },
      }}
      enableRowActions={true}
      renderRowActions={({ row }, index) => {
        return (
          <div key={index}>
            <IconButton size="small" component={Link} to={paths.front.volunteer.edit.replace(':id', row.original._id)}>
              <Edit color="primary" />
            </IconButton>
            <IconButton
              size="small"
              component={Link}
              to={paths.front.volunteer.profil.replace(':id', row.original._id)}
            >
              <AccountBox color="primary" />
            </IconButton>
            <IconButton
              size="small"
              component={Link}
              to={{ pathname: paths.front.volunteer.create, state: { duplicate: true, id: row.original._id } }}
            >
              <FileCopy color="primary" />
            </IconButton>
          </div>
        );
      }}
      enableToolbarInternalActions={true}
      renderTopToolbarCustomActions={() => {
        return (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircle />}
            onClick={() => history.push(paths.front.volunteer.create)}
          >
            {intl.button.add}
          </Button>
        );
      }}
      enableColumnFilters={true}
      tableInstanceRef={tableRef}
      {...tableState}
      autoResetPageIndex={false}
    />
  );
}

const VolunteerList = VolunteerListComponent;

export { VolunteerList };
